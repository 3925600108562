@charset 'utf-8';
@use '../../../mixin' as m;

/*----------
	top
----------*/
.l-structure .l-structure__main {
	margin-bottom: 0;
}

/* パーツ */
.top-title {
	&__main {
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 1.2;
	}

	&__sub {
		margin-top: 4px;
		font-weight: 1.4rem;
		font-weight: 500;
		line-height: 1.4;
	}

	@include m.mq-pc {
		&__main {
			font-size: 4.8rem;
		}

		&__sub {
			margin-top: 5px;
			font-weight: 1.6rem;
		}
	}
}

/* メインスライド */
.top-slide {
	position: relative;
	margin-bottom: 65px;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		z-index: 5;
		display: block;
		width: 96px;
		height: 100px;
		background: url(/assets/images/bg_shape_x.svg) no-repeat 0 0 / 96px auto;
	}

	&__inner {
		position: relative;
		margin-right: 56px;
		padding-bottom: 3px;

		&::before {
			content: '';
			position: absolute;
			right: -36px;
			top: 64px;
			z-index: 5;
			display: block;
			width: 108px;
			height: 72px;
			background: url(/assets/images/bg_shape.svg) no-repeat left bottom / 108px auto;
		}

		&::after {
			content: '';
			position: absolute;
			right: -56px;
			bottom: 0;
			z-index: 5;
			display: block;
			width: 110px;
			height: 100px;
			background: url(/assets/images/bg_shape_y.svg) no-repeat 0 0 / 110px auto;
		}
	}

	&__item {
		position: relative;
		width: 100%;
		height: auto;
	}

	&__item-img {
		width: 100%;
		height: 600px;
		object-fit: cover;
		object-position: center center;
	}

	&__item-copy {
		position: absolute;
		left: 20px;
		top: 27.3%;

		img {
			width: auto;
			height: 154px;
		}
	}

	&__control {
		display: flex;
		align-items: end;
		width: 100%;
		height: 10px;
		margin-top: 25px;
		padding: 0 20px;
	}

	&__pagination {
		position: relative;
		display: flex;

		> .swiper-pagination-bullet {
			opacity: 1;
			display: block;
			width: 10px;
			height: 10px;
			margin-right: 14px;
			background-color: #EAEAEA;

			&.swiper-pagination-bullet-active {
				background-color: var(--color-seco1);
			}
		}
	}

	&__start-stop {
		position: relative;
		width: 10px;
		height: 10px;
		cursor: pointer;

		&::before {
			@include m.iconfont-default;
			content: m.icon('stop');
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			font-size: 1rem;
			line-height: 10px;
			color: var(--color-seco1);
		}

		&.is-stop::before {
			content: m.icon('play');
		}
	}

	.swiper-container {
		visibility: hidden;

		&.swiper-container-initialized {
			visibility: visible;
		}
	}

	@include m.mq-pc {
		margin-bottom: 0;
		padding-right: 120px;

		&::before {
			width: 290px;
			height: 300px;
			background: url(/assets/images/bg_shape_x.svg) no-repeat 0 0 / 290px auto;
		}

		&__inner {
			margin-right: 0;
			padding-bottom: 126px;

			&::before {
				right: 0;
				top: 0;
				width: 216px;
				height: 144px;
				background: url(/assets/images/bg_shape.svg) no-repeat left bottom / 216px auto;
			}

			&::after {
				right: -120px;
				width: 376px;
				height: 343px;
				background: url(/assets/images/bg_shape_y.svg) no-repeat 0 0 / 376px auto;
			}
		}

		&__item-copy {
			left: calc(50% - 176px);
			top: 210px;

			img {
				height: 234px;
			}
		}

		&__control {
			align-items: stretch;
			justify-content: center;
			height: 14px;
			margin-top: 31px;
			padding: 0;
		}

		&__pagination {
			position: relative;
			display: flex;

			> .swiper-pagination-bullet {
				width: 13px;
				height: 13px;
				margin-right: 16px;
			}
		}

		&__start-stop {
			width: 14px;
			height: 14px;

			&::before {
				width: 14px;
				height: 14px;
				font-size: 1.4rem;
				line-height: 14px;
			}
		}
	}
}

/* ニュース */
.top-news-section {
	position: relative;
	margin-bottom: 80px;

	.c-tab {
		margin: 14px 0 30px;

		&__tab {
			margin-bottom: 30px;
		}
	}

	@include m.mq-pc {
		padding-top: 13px;
		margin-bottom: 120px;

		.top-title {
			position: absolute;
			left: 20px;
			top: 0;
		}

		.c-tab {
			margin: 0 0 50px;

			&__tab {
				margin-left: 220px;
				margin-bottom: 52px;
			}
		}
	}
}

/* 事業内容 */
.top-business-section {
	$this: &;
	position: relative;
	margin-bottom: 80px;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
		display: block;
		width: 108px;
		height: 72px;
		background: url(/assets/images/bg_shape.svg) no-repeat left bottom / 108px auto;
	}

	.top-title {
		@include m.inner-content(1200px);
		margin-bottom: 45px;
	}

	&__top-link-wrap {
		position: relative;
		z-index: 2;

		> a {
			display: block;
			margin-bottom: 50px;
			padding-bottom: 18px;
			border-bottom: solid 1px rgba(34, 34, 34, .2);
		}
	}

	&__top-link {
		margin-bottom: 15px;
		text-align: center;

		> span {
			position: relative;
			display: inline-block;
			color: var(--color-txt);
			font-size: 2rem;
			font-weight: 500;
			line-height: 1.3;

			&::after {
				@include m.iconfont-default;
				content: m.icon('arrow2-r');
				display: inline-block;
				width: 28px;
				height: 28px;
				margin-left: 20px;
				border: solid 1px var(--color-txt);
				line-height: 26px;
				font-size: 1rem;
				text-align: center;
				transform: translateY(-2px);
			}
		}
	}

	&__lead {
		font-size: 1.4rem;
		line-height: var(--line-height-l);
	}

	.c-link-block {
		position: relative;
		z-index: 2;
	}

	&__main-area {
		position: relative;
		padding: 50px 20px 100px;
		background-color: var(--color-bg);

		&::before {
			content: '';
			position: absolute;
			right: -106px;
			bottom: -101px;
			display: block;
			width: 250px;
			height: 257px;
			background: url(/assets/images/bg_single_slash.svg) no-repeat 0 0 / auto 257px;
		}
	}

	&__sub-area {
		padding: 0 20px 140px;
		background-color: var(--color-bg);
	}

	&__btn-view-list {
		margin-top: 50px;
	}

	@include m.mq-pc {
		position: relative;
		margin-bottom: 120px;

		&::after {
			left: calc(50% + 505px);
			width: 216px;
			height: 144px;
			background: url(/assets/images/bg_shape.svg) no-repeat left bottom / 216px auto;
		}

		.top-title {
			margin-bottom: 40px;
		}

		&__top-link-wrap {
			max-width: 1080px;
			margin: 0 auto;

			> a {
				margin-bottom: 30px;

				&:hover {
					border-color: rgba(34, 34, 34, 1);
				}
			}
		}

		&__top-link {
			margin-bottom: 12px;

			span {
				font-size: 2.4rem;
				transition: border-color .2s;

				&::after {
					width: 32px;
					height: 32px;
					margin-left: 30px;
					line-height: 30px;
					font-size: 1.2rem;
					transform: translateY(-3px);
				}

				a:hover & {
					text-decoration: underline;

					&::after {
						background-color: var(--color-txt);
						color: #ffffff;
					}
				}
			}
		}

		&__lead {
			font-size: 1.6rem;
			line-height: 2;
			text-align: center;
		}

		&__main-area {
			padding: 60px 20px 109px;

			&::before {
				right: calc(50% - 844px);
				bottom: -153px;
				width: 416px;
				height: 430px;
				background: url(/assets/images/bg_single_slash.svg) no-repeat 0 0 / auto 430px;
			}

			&::after {
				content: '';
				position: absolute;
				right: calc(50% + 575px);
				top: 57px;
				z-index: 1;
				display: block;
				width: 144px;
				height: 216px;
				background: url(/assets/images/bg_shape.svg) no-repeat left bottom / auto 216px;
			}

			.c-link-block {
				max-width: 1200px;
				margin-right: auto;
				margin-left: auto;
			}
		}

		&__sub-area {
			padding: 0 20px 200px;

			.c-link-block {
				gap: 80px 40px;
				max-width: 1200px;
				margin-right: auto;
				margin-left: auto;
			}
		}

		&__btn-view-list {
			margin-top: 100px;
		}
	}
}

/* ザイマックスについて */
.top-about-section {
	position: relative;
	margin-bottom: 80px;
	padding-top: 34px;

	&::before {
		content: '';
		position: absolute;
		left: 176px;
		top: 0;
		display: block;
		width: 214px;
		height: 170px;
		background: url(/assets/images/top/bg_single_slash_about.svg) no-repeat 0 0 / auto 170px;
	}

	.top-title {
		margin-bottom: 33px;
	}

	&__main-area {
		margin-bottom: 40px;
	}

	&__sub-area {
		padding: 0 17px;
	}

	&__sub-links {
		.c-textlink {
			max-width: none;
			margin-bottom: 22px;
		}
	}

	&__btn-view-list {
		margin-top: 46px;
	}

	@include m.mq-pc {
		margin-bottom: 120px;
		padding-top: 0;

		&::before {
			content: '';
			position: absolute;
			right: auto;
			left: 20px;
			top: 0;
			display: block;
			width: 500px;
			height: 514px;
			background: url(/assets/images/bg_single_slash.svg) no-repeat 0 0 / auto 514px;
		}

		.top-title {
			margin-bottom: 40px;
		}

		&__area-wrap {
			display: flex;
			justify-content: space-between;
		}

		&__main-area {
			width: 706px;
			margin-bottom: 0;
		}

		&__sub-area {
			width: 334px;
			padding: 0;
		}

		&__sub-links {
			width: 100%;

			.c-textlink {
				margin-bottom: 0;

				&:not(:first-child) {
					margin-top: 29px;
				}
			}
		}

		&__btn-view-list {
			margin-top: 70px;
		}
	}
}

/* 採用 */
.top-recruit-section {
	margin-bottom: 80px;

	a {
		display: block;
	}

	img {
		max-width: 1085px;
	}

	@include m.mq-pc {
		margin-bottom: 120px;

		a {
			transition: opacity .2s;

			&:hover {
				opacity: .4;
			}
		}
	}
}

/* バナー */
.top-bnr-section {
	overflow: hidden;
	position: relative;
	padding: 60px 20px;
	background-color: var(--color-bg);

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: url(/assets/images/bg_single_slash.svg) no-repeat left 270px top / 200px auto;
		// @media (min-width: 658px) and (max-width: 767px) {
		// 	background-size: contain;
		// }
	}

	&__title {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 30px;
		position: relative;
		z-index: 1;
	}

	&__list1-upper {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		border-left: solid 1px var(--color-border);
		border-top: solid 1px var(--color-border);
	}

	&__list1-upper-item {
		width: 50%;
		border-right: solid 1px var(--color-border);
		border-bottom: solid 1px var(--color-border);

		a {
			display: block;
		}
	}

	&__list1-lower {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 40px;
	}

	&__list1-lower-item {
		width: 50%;
		border-right: solid 1px var(--color-border);
		border-bottom: solid 1px var(--color-border);

		&:first-child {
			border-left: solid 1px var(--color-border);
		}

		a {
			display: block;
		}
	}

	&__list2 {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		border-left: solid 1px var(--color-border);
		border-top: solid 1px var(--color-border);
	}

	&__list2-item {
		width: 50%;
		border-right: solid 1px var(--color-border);
		border-bottom: solid 1px var(--color-border);

		a {
			display: block;
		}
	}

	@include m.mq-sp {

		&__list1-upper-item {
			&:first-child {
				width: 100%;
			}
		}

	}

	@include m.mq-pc {
		padding: 120px 20px;

		&::before {
			left: calc(50% - 100px);
			width: 840px;
			background: url(/assets/images/top/bg_single_slash_bnr.svg) no-repeat left top / contain;
		}

		&__title {
			font-size: 2.8rem;
			text-align: center;
			margin-bottom: 40px;
		}

		&__list1-upper {
			width: auto;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			justify-content: center;
		}

		&__list1-upper-item {
			width: 33.33%;
			background-color: #ffffff;

			a {
				transition: opacity .2s;

				&:hover {
					opacity: .4;
				}
			}
		}

		&__list1-lower {
			width: auto;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			justify-content: center;
			margin-bottom: 120px;
		}

		&__list1-lower-item {
			width: 33.33%;
			background-color: #ffffff;
			// stylelint-disable
			a {
				transition: opacity .2s;

				&:hover {
					opacity: .4;
				}
			}
			// stylelint-enable
		}

		&__list2 {
			width: auto;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			background-color: #ffffff;
		}

		&__list2-item {
			width: 33.33%;
			// stylelint-disable
			a {
				transition: opacity .2s;

				&:hover {
					opacity: .4;
				}
			}
			// stylelint-enable
		}
	}
}